import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress} from '@mui/material';
import { useEffect } from 'react';
import { getTransporteursList } from '../../Services/Transporteurs/Transporteurs';
import { createNavettes } from '../../Services/Navettes/Navettes';
import { canAccess } from '../../Services/Auth/Authentification';

export const SelectTransporteur = (props) => {
  let handleChange = props.handleChange;
  let transporteur = props.transporteur;
  const [loading, setLoading] = React.useState(true);
  const [carriers, setCarriers] = React.useState(null);

  function removeDuplicates(objects) {
    var uniqueObjects = [];
    var encounteredNames = [];
  
    objects.forEach(function(obj) {
      var normalizedName = obj.name.toLowerCase();
  
      if (!encounteredNames.includes(normalizedName)) {
        uniqueObjects.push(obj);
        encounteredNames.push(normalizedName);
      }
    });
  
    return uniqueObjects;
  }

  useEffect(() => {
      
    setLoading(true);
    getTransporteursList().then(carriers => {
                setLoading(false);
                let removeDuplicate = new Array();
                var uniqueTransporteur = removeDuplicates(carriers['hydra:member']);
                setCarriers(uniqueTransporteur);
    });
  
  }, []);

  return (
    <FormControl fullWidth>
      {loading? <CircularProgress/> : 
      <>
        <InputLabel id="demo-simple-select-label" style={{top: "10px"}}>Transporteur</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          margin="dense"
          type="datetime-local"
          fullWidth
          variant="standard"
          value={transporteur}
          label="Transporteur"
          onChange={handleChange}
          style={{overflow: "hidden", height: "40px"}}
        >
          {
            carriers.map((transporteur)=>{
              return <MenuItem value={transporteur['@id']}>{transporteur.name}</MenuItem>
            })
          }
        </Select>
      </>
      }
    </FormControl>
  );
}

const CreateNavette = (props) => {
  let handleReload = props.handleReload;
  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  var time = today.getHours() + ":" + today.getMinutes();
  var dateTime = date+'T'+time;
  const [open, setOpen] = React.useState(false);

  const [transporteur, setTransporteur] = React.useState('');
  const [timeselect, setTimeselect] = React.useState(dateTime);
  const [label, setLabel] = React.useState("");
  /** */

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = (event) => {
    setTransporteur(event.target.value);
  };

  const handleChangeTime = (event) => {
    setTimeselect(event.target.value);
  };

  function createNavetteToApi(){
    createNavettes(transporteur, timeselect, label);
    setOpen(false);
    handleReload();
  }

  return (
    <div>
      {canAccess(['ROLE_RESPONSABLE', 'ROLE_ADMIN'])? <Button variant="outlined" onClick={handleClickOpen}>
        Créer une navette
      </Button>:<></>}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Création d'une navette</DialogTitle>
        <DialogContent>
            <TextField id="filled-basic" label="Label" variant="filled" style={{width: "100%"}} value={label} onChange={e => setLabel(e.target.value)}/>
            <SelectTransporteur handleChange={handleChange} transporteur={transporteur}/>
            <TextField
                autoFocus
                id="datetime-local"
                label="Arrivée de la navette"
                margin="dense"
                type="datetime-local"
                fullWidth
                variant="standard"
                defaultValue={timeselect}
                onChange={handleChangeTime}
                InputLabelProps={{
                  shrink: true,
                }}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
          <Button onClick={createNavetteToApi}>Ajouter</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default CreateNavette;